const baseMainnet = {
  chainId: 8453,
  name: 'Base',
  currency: 'ETH',
  explorerUrl: 'https://basescan.org',
  rpcUrl: process.env.REACT_APP_BASE_MAINNET_RPC!
}

const baseSepolia = {
  chainId: 84532,
  name: 'Base Sepolia Testnet',
  currency: 'ETH',
  explorerUrl: 'https://sepolia.etherscan.io',
  rpcUrl: process.env.REACT_APP_BASE_SEPOLIA_RPC!
}

export {
  baseMainnet,
  baseSepolia
}