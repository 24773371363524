import React, { useState, useEffect, useRef } from 'react';
import { useWeb3ModalAccount, useWeb3ModalProvider, useWeb3Modal, useDisconnect } from '@web3modal/ethers/react';
import { BrowserProvider, ethers } from 'ethers';
import ConnectButton from './ConnectButton';
import MainButton from './MainButton';
import { NFTList } from './NFTList'; 
import PhygitalCollectionABI from '../configs/PhygitalCollectionABI.json';
import { NFT, Boost, NFTListProps, TokenRedemptionProps, Step, PairingResult, ClaimResult } from './types';
import { fetchBoosts } from './boostService';

const TARGET_CHAIN_ID = Number(process.env.REACT_APP_WALLET_CONNECT_TARGET_CHAIN_ID)!
const API_URL = process.env.REACT_APP_BACKEND_API!
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS!
const COLLECTION_ID = Number(process.env.REACT_APP_COLLECTION_ID)
const IPFS = process.env.REACT_APP_IPFS!
const RPC = process.env.REACT_APP_RPC_URL!

function convertToTokens(amount: bigint, decimals: number = 18): string {
  return ethers.formatUnits(amount, decimals);
}

export function TokenRedemption({ uid, nfcStatus, piccData, enc, cmac, nft_token_id, nft_collection_id }: TokenRedemptionProps) {
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const [nftId, setNftId] = useState('');
  const { walletProvider } = useWeb3ModalProvider()
  const [errorMessage, setErrorMessage] = useState('');
  const [jwtToken, setJwtToken] = useState(null);
  const { open } = useWeb3Modal()
  const { disconnect } = useDisconnect()
  const authAttemptedRef = useRef(false);
  const [matchingNFT, setMatchingNFT] = useState<NFT | null>(null);
  const [claimStatus, setClaimStatus] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState<Step>(Step.ConnectWallet);
  const [pairingResult, setPairingResult] = useState<PairingResult | null>(null);
  const [isClaimingTokens, setIsClaimingTokens] = useState(false);
  const [withdrawableAmount, setWithdrawableAmount] = useState<string | null>(null);
  const [boosts, setBoosts] = useState<Boost[]>([]);
  const [selectedBoostIndices, setSelectedBoostIndices] = useState<number[]>([]);
  const [availableTokens, setAvailableTokens] = useState<string | null>(null);
  const [isLoadingBoosts, setIsLoadingBoosts] = useState(false);
  const [isAuthPending, setisAuthPending] = useState<boolean>(false);
  const [claimResult, setClaimResult] = useState<ClaimResult | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isMetamaskBrowser, setIsMetamaskBrowser] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      const ua = navigator.userAgent;
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua));
      // setIsMobile(true);
    };

    const checkMetamaskBrowser = () => {
      // Check if we're in the MetaMask mobile app browser
      const userAgent = navigator.userAgent.toLowerCase();
      const isMetaMaskMobile = userAgent.includes('metamask') && userAgent.includes('mobile');
      
      // Check if the ethereum object is present and is MetaMask
      const isMetaMaskDesktop = 
        typeof window.ethereum !== 'undefined' && 
        'isMetaMask' in window.ethereum && 
        window.ethereum.isMetaMask === true;

      setIsMetamaskBrowser(isMetaMaskMobile);
    };
      
  

    checkMobile();
    checkMetamaskBrowser();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const generateMetamaskDeeplink = () => {
    const currentUrl = window.location.href;
    return `https://metamask.app.link/dapp/${currentUrl.split('//')[1]}`;
  };
  
  useEffect(() => {
    if (nfcStatus === 1 && nft_token_id) {
      setMatchingNFT({ id: String(nft_token_id), image: '', nfcID: uid });
      fetchWithdrawableAmount(String(nft_token_id));
    } else if (nfcStatus === 0) {
      setCurrentStep(Step.PairNFT);
    }
  }, [nfcStatus, nft_token_id]);

  useEffect(() => {
    if (isConnected) {
      if (!authAttemptedRef.current) {
        authenticateWallet();
      }
      if (nfcStatus === 1 && nft_token_id) {
        setCurrentStep(Step.ClaimTokens);
      }
    }
  }, [isConnected]);

  useEffect(() => {
    if (!isConnected) {
      // Reset states when disconnected
      setCurrentStep(Step.ConnectWallet);
      setJwtToken(null);
      // setMatchingNFT(null);
      // setNftId('');
      setClaimStatus(null);
      setPairingResult(null);
      setClaimResult(null);
      setSelectedBoostIndices([]);
      authAttemptedRef.current = false;
      if (nfcStatus === 1 && nft_token_id) {
        fetchWithdrawableAmount(String(nft_token_id));
      }
    }
  }, [isConnected]);

  const authenticateWallet = async () => {
    if (authAttemptedRef.current) return;
    authAttemptedRef.current = true;

    setisAuthPending(true);
    try {
      // Step 1: Get nonce
      const nonceResponse = await fetch(`${API_URL}/auth/generate-nonce`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ address })
      });
      const { nonce } = await nonceResponse.json();

      // Step 2: Sign nonce
      const provider = new BrowserProvider(walletProvider!)
      const signer = await provider.getSigner()
      const signature = await signer.signMessage(nonce);

      // Step 3: Verify signature
      const verifyResponse = await fetch(`${API_URL}/auth/verify-signature`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ address, signature })
      });
      const { token } = await verifyResponse.json();

      setJwtToken(token);
    } catch (err: any) {
      setErrorMessage('Authentication failed: ' + err.message);
    } finally {
      setisAuthPending(false);
    }
  };

  const handleMatchingNFTFound = (nft: NFT) => {
    setMatchingNFT(nft);
    setNftId(nft.id);
    if (currentStep !== Step.PairingSuccess) {
      setCurrentStep(Step.SelectBoosts);
    }
  };

  const handlePairSuccess = (result: PairingResult) => {
    setPairingResult(result);
    setCurrentStep(Step.PairingSuccess);
  };

  const claimTokens = async () => {
    if (!matchingNFT) {
      setErrorMessage('No matching NFT found');
      return;
    }

    setIsClaimingTokens(true);
    setErrorMessage('');

    try {
      const provider = new BrowserProvider(walletProvider!)
      const signer = await provider.getSigner()
      
      const contract = new ethers.Contract(CONTRACT_ADDRESS!, PhygitalCollectionABI, signer);
      const nonce = await contract.getNonce(matchingNFT.id);

      const deadline = Math.floor(Date.now() / 1000) + 180;

      const boostsPayload = selectedBoostIndices.map(index => ({
        token_id: boosts[index].boost_token_id,
        boost_type: boosts[index].boost_type
      }));

      const payload = {
        nftId: parseInt(matchingNFT.id),
        collectionId: COLLECTION_ID || nft_collection_id,
        boosts: boostsPayload,
        deadline: deadline,
        nonce: nonce.toString()
      };

      const response = await fetch(`${API_URL}/token/get_signature`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.error && errorData.error.includes("User does not own the specified NFT")) {
          throw new Error("Your digital twin NFT was not found in the connected wallet. Please ensure you're using the correct wallet.");
        }
        throw new Error(errorData.error || 'Failed to get signature from backend');
      }

      const data = await response.json();
      const { signature, totalBoostValueStr } = data;

      const tx = await contract.claim(
        parseInt(matchingNFT.id),
        totalBoostValueStr,
        deadline,
        '0x'+signature
      );

      setClaimStatus('Transaction sent. Waiting for confirmation...');

      const receipt = await tx.wait();

      const transferEvent = receipt.logs.find(
        (log: any) => log.topics[0] === ethers.id("Transfer(address,address,uint256)")
      );

      if (transferEvent) {
        const totalAmount = parseFloat(ethers.formatEther(transferEvent.data));
        const boostPercentage = parseFloat(ethers.formatUnits(totalBoostValueStr, 25)); // 10^25 = 1%
        const baseAmount = totalAmount / (1 + boostPercentage / 100);
        const boostedAmount = totalAmount - baseAmount;

        const result: ClaimResult = {
          baseAmount,
          boostedAmount,
          boostPercentage,
          totalAmount
        };

        setClaimResult(result);
        setCurrentStep(Step.ClaimSuccess);
      } else {
        setErrorMessage('Tokens claimed successfully, but couldn\'t find token transfer amount.');
      }

    } catch (err: any) {
      setErrorMessage('Failed to claim tokens: ' + err.message);
      setClaimStatus(null);
    } finally {
      setIsClaimingTokens(false);
    }
  };


  useEffect(() => {
    if (matchingNFT && currentStep === Step.ClaimTokens && jwtToken) {
      setIsLoadingBoosts(true);
      fetchBoosts(jwtToken)
        .then(fetchedBoosts => {
          setBoosts(fetchedBoosts);
          setIsLoadingBoosts(false);
        })
        .catch(error => {
          console.error('Error fetching boosts:', error);
          setErrorMessage('Failed to fetch boosts');
          setIsLoadingBoosts(false);
        });
    }
  }, [currentStep, jwtToken]);

  const fetchWithdrawableAmount = async (nftId: string) => {
    try {
      let provider;
      if (walletProvider) {
        provider = new BrowserProvider(walletProvider);
      } else {
        console.log(RPC, TARGET_CHAIN_ID);
        provider = new ethers.JsonRpcProvider(RPC, TARGET_CHAIN_ID);
      }

      const contract = new ethers.Contract(CONTRACT_ADDRESS, PhygitalCollectionABI, provider);

      const boost = BigInt(0); // Assuming no boost, adjust if needed
      const amount = await contract.getWithdrawableAmount(BigInt(nftId), boost);
      // console.log("0% boost", await contract.getWithdrawableAmount(BigInt(nftId), BigInt(0)))
      // console.log("1% boost", await contract.getWithdrawableAmount(BigInt(nftId), BigInt('1' + '0'.repeat(25))))
      // console.log("100% boost", await contract.getWithdrawableAmount(BigInt(nftId), BigInt('1' + '0'.repeat(27))))
      const formattedAmount = convertToTokens(amount);
      setAvailableTokens(formattedAmount);
      setWithdrawableAmount(formattedAmount);
    } catch (error) {
      console.error('Error fetching withdrawable amount:', error);
      setErrorMessage('Failed to fetch withdrawable amount');
    }
  };


  const renderAddressAndUid = () => {
    const trimmedAddress = address ? `${address.slice(0, 3)}...${address.slice(-3)}` : '';

    return (
      <>
        {isConnected && (
          <div>
            <hr></hr>
            <small>Connected wallet {trimmedAddress}</small>
            <p className='small'>
            <ConnectButton type='disconnect' textButton/>
            </p>
          </div>
        )}
        
        <small>
          NFC UID: {uid}
        </small>
      </>
    );
  };

  const renderErrorAlert = () => {
    if (!errorMessage) return null;

    return (
      <div className="sticky-error-alert">
        <div className="alert alert-error">
          {errorMessage}
        </div>
      </div>
    );
  };
  const renderConnectWalletStep = () => (
    <div>
      <h2 className="big-title has-mask-fill primary-font-title">CLAIM YOUR FIJI</h2>
      {availableTokens && (
        <>
        <hr/><h5 className='small'>{parseFloat(availableTokens).toFixed(4)} FIJI TOKENS</h5>
        <p >available to claim</p><hr/>
        </>
      )}
      <ConnectButton type="connect" />
      {isMobile && !isMetamaskBrowser && (
        <>
          <p>or</p>
          <div>
            <div className="clapat-button-wrap">
              <div className="clapat-button">
                <button 
                  className="button-border rounded outline"
                  onClick={() => window.open(generateMetamaskDeeplink(), '_blank')}
                  type="button"
                >
                  <span data-hover="Open in Metamask">Open in Metamask</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <hr/>
      {renderAddressAndUid()}
    </div>
  );

  const handleBoostSelection = (index: number) => {
    setSelectedBoostIndices(prevIndices => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter(i => i !== index);
      } else {
        return [...prevIndices, index];
      }
    });
  };

  const renderBoostGrid = () => (
    <div>
      <h4 className="has-mask-fill">Select your boosts</h4>
      {isLoadingBoosts ? (
        <p>Loading boosts...</p>
      ) : boosts.length > 0 ? (
        <div className="nft-grid">
          {boosts.map((boost, index) => (
            <div 
              key={index} 
              className={`nft-item ${selectedBoostIndices.includes(index) ? 'selected' : ''}`}
              onClick={() => handleBoostSelection(index)}
            >
              <img src={boost.image_url.replace('ipfs://', IPFS)} alt={`Boost ${boost.boost_type}`} /><hr/>
              <div className="white">
                <span>{boost.boost_type}</span>
                <span> {boost.value ? (boost.value / 100) + "%" : "?"}</span>
                {boost.uses_left !== null && <p><small>{boost.uses_left} uses left</small></p>}
                {boost.duration && (<p><small>{boost.duration} days left</small></p>)}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <p>You don't have any boosts yet.</p>
        </div>
      )}
    </div>
  );

  const renderStep = () => {
    switch (currentStep) {
      case Step.ConnectWallet:
        return renderConnectWalletStep();
      case Step.PairNFT:
        if (isAuthPending) return <div><h2 className="big-title has-mask-fill primary-font-title">Login request</h2><p>Please sign transaction in your wallet</p></div>;
        return (
          <div>
            <NFTList 
              uid={uid} 
              jwtToken={jwtToken} 
              onMatchingNFTFound={handleMatchingNFTFound}
              onPairSuccess={handlePairSuccess}
              piccData={piccData}
              enc={enc}
              cmac={cmac}
            />
            {renderAddressAndUid()}
          </div>
        );
      case Step.PairingSuccess:
        return (
          <div>
            <h2 className="big-title has-mask-fill primary-font-title">Pairing Successful</h2>
            <p>Your Digital Twin paired successfully</p>
            {pairingResult && (
              <div className="pairing-result">
                <p>Received {pairingResult.baseAmount.toFixed(4)} FIJI tokens</p>
                <p>Plus boosted {pairingResult.boostedAmount.toFixed(4)} FIJI tokens ({pairingResult.boostPercentage.toFixed(2)}% boost used)</p>
                <p className="total-amount"><strong>Total: {pairingResult.totalAmount.toFixed(4)} FIJI tokens</strong></p><hr/>
              </div>
            )}
            <p>You're all set! Scan your phygital item again to claim more tokens</p>
            {renderAddressAndUid()}
          </div>
        );
      case Step.ClaimTokens:
        if (isAuthPending) return <div><h2 className="big-title has-mask-fill primary-font-title">Login request</h2><p>Please sign transaction in your wallet</p></div>;
        return (
          <div>
            <h2 className="big-title has-mask-fill primary-font-title">Claim Your Tokens</h2>
            {withdrawableAmount && (
              <p>{parseFloat(withdrawableAmount).toFixed(4)} FIJI available to claim</p>
            )}
            {!isClaimingTokens && (
              <>
                {isLoadingBoosts ? (
                  <p>Loading boosts...</p>
                ) : (
                  jwtToken && renderBoostGrid()
                )}
              </>
            )}
            {isClaimingTokens && (
              <p>Claiming tokens</p>
            )}
            <MainButton
              text="Claim Tokens"
              hoverText="Claim Your FIJI Tokens"
              loadingText="Claiming"
              handler={claimTokens}
              disabled={chainId !== TARGET_CHAIN_ID}
              loading={isClaimingTokens}
            />
            {renderAddressAndUid()}
          </div>
        );
      case Step.ClaimSuccess:
        return (
          <div>
            <h2 className="big-title has-mask-fill primary-font-title">Claim Successful</h2>
            {claimResult && (
              <div className="claim-result">
                <p>Received {claimResult.baseAmount.toFixed(4)} FIJI tokens</p>
                <p>Plus boosted {claimResult.boostedAmount.toFixed(4)} FIJI tokens ({claimResult.boostPercentage.toFixed(2)}% boost used)</p>
                <p className="total-amount"><strong>Total: {claimResult.totalAmount.toFixed(4)} FIJI tokens</strong></p>
              </div>
            )}
            {renderAddressAndUid()}
          </div>
        );
    }
  };

  return (
    <>
      {renderErrorAlert()}
      <div className="token-redemption-content">
        {renderStep()}
      </div>
    </>
  );
}