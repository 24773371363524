import { Boost } from './types';

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

export const fetchBoosts = async (jwtToken: string): Promise<Boost[]> => {
  try {
    const response = await fetch(`${BACKEND_API}/boosts/user`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`,
        'method': 'GET',
        'Content-Type': 'application/json',
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch boosts');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching boosts:', error);
    throw error;
  }
};