import { useDisconnect, useWeb3Modal } from '@web3modal/ethers/react'
import MainButton from './MainButton';

type ButtonType = 'connect' | 'disconnect';
interface Props {
  type: ButtonType,
  textButton?: boolean,
  stickyOnMobile?: boolean
};

const buttonText = (type: ButtonType) => {
  if(type === 'connect') {
    return 'Connect Wallet'

  }else if(type === 'disconnect') {
    return 'Disconnect'
  }else{
    return 'Disabled'
  }
}
const currentMethod = (open: ()=>Promise<void>, disconnect: ()=>Promise<void>, type: ButtonType) => {
  if(type === 'connect') return open;
  if(type === 'disconnect') return disconnect;
}

export default function ConnectButton(props: Props) {
  const {type, textButton, stickyOnMobile} = props
  // Use modal hook
  const { open } = useWeb3Modal()
  const { disconnect } = useDisconnect()
  if(textButton) {
    return  (
      <div className="button-wrap large-btn text-button">
        <a onClick={()=>currentMethod(open, disconnect, type)!()}>
          <div className="button-text sticky right">
            <span data-hover={buttonText(type)}>{buttonText(type)}</span>
          </div>
        </a> 
      </div>
    )
  }
  return (
    <MainButton
      text={buttonText(type)}
      hoverText={buttonText(type)}
      handler={()=>currentMethod(open, disconnect, type)!()}
      loadingText={"Please wait"}
      disabled={!type}
      stickyOnMobile={stickyOnMobile}
    />
  )
}